import { Input, Checkbox, Col, Row, Select, Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import GoalService from "../../services/goals.services";
import "./registration.css";

const backgroundStyle = {
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const goalTextStyle = {
  fontSize: 14,
  marginTop: 3,
  marginLeft: 10,
  color: "#242629",
};
const checkboxDivStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
};

const Page1 = (props) => {
  const { allValues } = props;
  const [form] = Form.useForm();
  const [goals, setGoals] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState([]);

  useEffect(() => {
    form.setFieldsValue(allValues);
    setSelectedGoals(allValues.selectedGoals || []);
    if (allValues.goalOtherSelected) {
      setSelectedGoals(allValues.selectedGoals.concat(["other"]));
    }
  }, [allValues, form]);

  useEffect(() => {
    GoalService.getBusinessGoals()
      .then((res) => {
        setGoals(res);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  const handleGoalChange = (value, e) => {
    if (selectedGoals.includes(value)) {
      setSelectedGoals(selectedGoals.filter((x) => x !== value));
    } else {
      setSelectedGoals(selectedGoals.concat([value]));
    }
  };

  const onSubmit = (values) => {
    let goalOtherSelected = false;
    if (selectedGoals.includes("other")) {
      goalOtherSelected = true;
    }
    props.onSubmit(
      {
        ...values,
        selectedGoals: selectedGoals.filter((x) => x !== "other"),
        goalOtherSelected,
      },
      1
    );
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch(() => {
        // incomplete form
      });
  };

  let mappedGoals = goals.map((x) => {
    return {
      value: x.goal_id,
      label: x.goal_text,
    };
  });
  mappedGoals.push({ value: "other", label: "Other" });

  const theme = "light";
  return (
    <div
      style={{ marginTop: "3%" }}
      className={`h-100 ${theme === "light" ? "bg-white" : ""}`}
    >
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={24} sm={24} md={24} lg={12}>
          <Form
            form={form}
            name="basicInformation"
            layout="vertical"
            style={{ width: "100%" }}
            onFinish={onSubmit}
          >
            <div className="container d-flex flex-column justify-content-center h-100">
              <p style={{ fontWeight: "bold", fontSize: 24, color: "#7030A0" }}>
                Thank you for deciding to join Bigfanz!
              </p>
              <p style={{ fontSize: 18, opacity: 0.8, marginTop: -10 }}>
                We are confident the journey will be extremely rewarding for
                you.
              </p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input your business name",
                  },
                ]}
                label="Business Name"
                name="businessName"
              >
                <Input placeholder="Enter Business Name" />
              </Form.Item>
              <Form.Item
                label="How did you hear our awesome Loyalty Programs?"
                name="referralSource"
                rules={[
                  {
                    required: true,
                    message: "Please input referral source",
                  },
                ]}
              >
                <Select
                  className="w-100 mr-2"
                  style={{ minWidth: 180 }}
                  placeholder="Select referral source"
                  options={[
                    {
                      value: "Recommendation/Search",
                      label: "Recommendation/Search",
                    },
                    {
                      value: "Reference",
                      label: "Reference",
                    },
                    {
                      value: "Google",
                      label: "Google",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Goals"
                rules={[
                  {
                    required: true,
                    message: "Please select goals",
                  },
                ]}
              >
                {mappedGoals.map((x, i) => (
                  <div key={i} style={checkboxDivStyle}>
                    <Checkbox
                      checked={selectedGoals.includes(x.value)}
                      onChange={(e) => handleGoalChange(x.value, e)}
                    />
                    <p style={goalTextStyle}>{x.label}</p>
                  </div>
                ))}
              </Form.Item>
              {selectedGoals.includes("other") && (
                <Form.Item name="goalOtherText">
                  <Input placeholder="Add Custom Goal" />
                </Form.Item>
              )}
              <div style={{ ...checkboxDivStyle }}>
                <Button
                  type="primary"
                  block
                  style={{ width: "25%" }}
                  onClick={onFinish}
                >
                  Next
                </Button>
                <a href="/">
                  <Button type="text" style={{ color: "#7030A0" }}>
                    Back to Home
                  </Button>
                </a>
              </div>
            </div>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={0} lg={12}>
          <div
            className="d-flex flex-column justify-content-between h-100 px-4"
            style={backgroundStyle}
          >
            <Row justify="center">
              <Col xs={0} sm={0} md={0} lg={20}>
                <img
                  className="img-fluid mb-5"
                  src="assets/images/registration-1.png"
                  alt=""
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Page1;
