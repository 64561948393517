import { Input, Col, Row, Button, Form, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import IndustryService from "../../services/industryServices";

const backgroundStyle = {
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const goalTextStyle = {
  fontSize: 14,
  marginTop: 3,
  marginLeft: 10,
  color: "#242629",
};

const checkboxDivStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
};

const Page2 = (props) => {
  const { allValues } = props;
  const [form] = Form.useForm();
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");

  useEffect(() => {
    form.setFieldsValue(allValues);
    setSelectedIndustry(allValues.industryType);
    if (allValues.industryOtherFlag) {
      setSelectedIndustry("other");
    }
  }, [allValues, form]);

  useEffect(() => {
    IndustryService.getIndustries(props.token)
      .then((res) => {
        setIndustries(res);
      })
      .catch((err) => {
        alert("Fetching industry type failed");
      });
  }, [props.token]);

  const onIndustryChanged = (id, e) => {
    if (e.target.checked) setSelectedIndustry(id);
    else setSelectedIndustry("");
  };

  const onSubmit = (values) => {
    props.onSubmit(
      {
        ...values,
        industryOtherFlag: selectedIndustry === "other",
        industryType: (selectedIndustry === "other" && "") || selectedIndustry,
      },
      2
    );
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch(() => {
        // incomplete form
      });
  };

  const theme = "light";

  return (
    <div
      style={{ marginTop: "3%" }}
      className={`h-100 ${theme === "light" ? "bg-white" : ""}`}
    >
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={24} sm={24} md={24} lg={12}>
          <Form
            form={form}
            name="basicInformation"
            layout="vertical"
            style={{ width: "100%" }}
            onFinish={onSubmit}
          >
            <div className="container d-flex flex-column justify-content-center h-100">
              <p style={{ fontWeight: "bold", fontSize: 24, color: "#7030A0" }}>
                Tell us a little about your business{" "}
              </p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input your location count",
                  },
                ]}
                label="How many stores / shops / restaurants do you have?"
                name="locationCount"
              >
                <Input type="number" placeholder="Eg. 1" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input Bigfanz location count",
                  },
                ]}
                label="How many locations will be using Bigfanz?"
                name="bigfanzLocationCount"
              >
                <Input type="number" placeholder="Eg. 1" />
              </Form.Item>

              <Form.Item label="What kind of industry do you have?">
                {industries.map((x, i) => (
                  <div key={i} style={checkboxDivStyle}>
                    <Checkbox
                      checked={selectedIndustry === x.industry_id}
                      onChange={(e) => onIndustryChanged(x.industry_id, e)}
                    />
                    <p style={goalTextStyle}>{x.industry_name}</p>
                  </div>
                ))}
                <div style={checkboxDivStyle}>
                  <Checkbox
                    checked={selectedIndustry === "other"}
                    onChange={(e) => onIndustryChanged("other", e)}
                  />
                  <p style={goalTextStyle}>Other</p>
                </div>
              </Form.Item>
              {selectedIndustry === "other" && (
                <Form.Item
                  name="industryOtherDesc"
                  rules={[
                    {
                      required: true,
                      message: "Please enter custom industry type",
                    },
                  ]}
                >
                  <Input placeholder="Add Custom Industry Type" />
                </Form.Item>
              )}
              <div style={{ ...checkboxDivStyle, marginTop: 20 }}>
                <Button
                  type="primary"
                  // htmlType="submit"
                  block
                  style={{ width: "25%" }}
                  onClick={onFinish}
                >
                  Next
                </Button>
                <Button
                  type="text"
                  style={{ color: "#7030A0", width: "25%" }}
                  onClick={props.onPrevious}
                >
                  Back
                </Button>
              </div>
            </div>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={0} lg={12}>
          <div
            className="d-flex flex-column justify-content-between h-100 px-4"
            style={backgroundStyle}
          >
            <Row justify="center">
              <Col xs={0} sm={0} md={0} lg={20}>
                <img
                  className="img-fluid mb-5"
                  src="assets/images/registration-1.png"
                  alt=""
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Page2;
