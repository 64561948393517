// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-row-start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  };

.full-width {
  width: 10px; 
}

.ant-form-item-label label {
  font-family: 'Poppins', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/pages/registration/registration.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB,CAAA;;AAEF;EACE,WAAW;AACb;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".flex-row-start {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n  };\n\n.full-width {\n  width: 10px; \n}\n\n.ant-form-item-label label {\n  font-family: 'Poppins', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
