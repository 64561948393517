const { API_BASE_URL } = require("./../constants/ApiConstant");

export default class ProspectService {
  static addProspect(data) {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/v1/prospect`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            resolve(res.data);
          } else {
            reject({ message: res.message });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
