import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import { states } from "../../constants/states";
import { ROW_GUTTER } from "./../../constants/ThemeConstant";

const checkboxDivStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
};

const Page3 = (props) => {
  const { allValues } = props;
  const theme = "light";
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(allValues);
  }, [allValues, form]);

  const onSubmit = (values) => {
    props.onSubmit(values, 3);
  };

  return (
    <div
      style={{
        marginTop: "3%",
        width: window.innerWidth,
        padding: "0px 10% 0px 10%",
      }}
      className={`h-100 ${theme === "light" ? "bg-white" : ""}`}
    >
      <Form
        form={form}
        name="basicInformation"
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onSubmit}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: 24,
            color: "#7030A0",
            fontFamily: "Poppins",
          }}
        >
          Please share your contact Information
        </p>
        <Row gutter={ROW_GUTTER} justify="start">
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your first name",
                },
              ]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please input your last name",
                },
              ]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} justify="start">
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email address",
                },
              ]}
            >
              <Input placeholder="Enter email address" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="Phone Number"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number",
                },
              ]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} justify="start">
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item label="Website" name="website_url_1">
              <Input placeholder="Enter Website" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your address",
                },
              ]}
              label="Address 1"
              name="reply_address_1"
            >
              <Input placeholder="Enter Address 1" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} justify="start">
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your city",
                },
              ]}
              label="City"
              name="reply_city"
            >
              <Input placeholder="Enter City" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select your state",
                },
              ]}
              label="State"
              name="reply_state"
            >
              <Select showSearch placeholder="Select State">
                {states.map((state) => (
                  <Select.Option key={state} value={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div style={{ ...checkboxDivStyle, marginTop: 20 }}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ width: 150 }}
              // onClick={props.onNext}
            >
              Next
            </Button>
          </Form.Item>
          <Button
            type="text"
            style={{ color: "#7030A0", width: 150 }}
            onClick={props.onPrevious}
          >
            Back
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Page3;
