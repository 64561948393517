import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import Navigation from "../../components/navigation";
import ContactReqService from "../../services/contactReq.services";
import PlanService from "../../services/plans.services";
import ProspectService from "../../services/prospect.services";

const Home = () => {
  const [plans, setPlans] = useState([]);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMsg, setContactMsg] = useState("");
  const [reqDemoData, setReqDemoData] = useState({});
  const [loading, setLoading] = useState(false);

  // const [reqDemoEmailVerifyModal, setReqDemoEmailVerifyModal] = useState(false);

  useEffect(() => {
    PlanService.getBusinessPlans()
      .then((res) => {
        setPlans(res);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  const formatBillFrequency = (days) => {
    if (days === 1) return "Per day";
    else if (days === 7) return "Per week";
    else if (days === 30) return "Per month";
    else if (days === 365) return "Per year";
  };

  const formatLY = (days) => {
    if (days === 1) return "Daily";
    else if (days === 7) return "Weekly";
    else if (days === 30) return "Monthly";
    else if (days === 365) return "Yearly";
  };

  const onSendMessage = () => {
    if (!contactName || !contactEmail || !contactMsg) {
      message.error("Please enter all the fields to contact us");
    } else {
      setLoading(true);
      ContactReqService.addContactReq(contactName, contactEmail, contactMsg)
        .then((res) => {
          message.success("Contact Request Submitted");
          setContactName("");
          setContactEmail("");
          setContactMsg("");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.message);
          setLoading(false);
        });
    }
  };

  const onRequestDemo = () => {
    const {
      business_name,
      address_line_1,
      city,
      state,
      zip,
      contact_name,
      contact_email,
      contact_phone,
      prospect_comment,
      business_phone_1,
    } = reqDemoData;
    if (
      !business_name ||
      !address_line_1 ||
      !city ||
      !state ||
      !zip ||
      !contact_name ||
      !contact_email ||
      !contact_phone
    ) {
      message.error("Please enter all the fields to request a demo");
    } else {
      setLoading(true);
      ProspectService.addProspect({
        business_name,
        address_line_1,
        city,
        state,
        zip,
        contact_name,
        contact_email,
        contact_phone,
        prospect_comment,
        business_phone_1,
      })
        .then((res) => {
          message.success("Demo Request Submitted");
          setReqDemoData({});
          setLoading(false);
        })
        .catch((err) => {
          message.error("Submitting Demo Request Failed");
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Navigation />
      <section className="hero-banner-v1 position-relative" id="home">
        <div className="cloud bg-size"> </div>
        <div className="bg-one"></div>
        <div className="bg-two"></div>
        <div className="bg-three"></div>
        <div className="ballons">
          <img
            src="assets/images/ballons.png"
            className="balloon2"
            alt="ballon2"
          />
        </div>
        <div className="ballons1">
          <img
            src="assets/images/ballons1.png"
            className="balloon2"
            alt="balloon2"
          />
        </div>
        <div className="container-1450">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="hero-content">
                <h1 className="wow fadeInUp" data-wow-delay=".5s">
                  Turn your customers into your <span>biggest fans!</span>
                </h1>
                <p className="wow fadeInDown" data-wow-delay="1s">
                  Bigfanz is a next generation customer engagement and loyalty
                  platform for small businesses and their customers that help to
                  build lasting relationships and drive growth.
                </p>
                <div className="text-center text-request-book">
                  <a
                    href="#requestDemo"
                    className="mt-3 mb-5 main-btn arrow-btn wow fadeInUp"
                    data-wow-delay=".5s"
                  >
                    Request a Demo
                  </a>
                  <img src="assets/images/center-bg-image.png" alt="bg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="gotribefit-client-sec space-ptb80 mb-banner-go-tribe-80"
        id="about"
      >
        <div className="container-1450">
          <div className="row gx-5 d-flex align-items-end gotribefit-col-reverse">
            <div className="pl-0 col-lg-6 gotribefit-img-client-new my-auto">
              <img
                src="assets/images/dashboard-1.png"
                alt=""
                className="img-fluid "
              />
            </div>
            <div className="col-lg-6 gotribefit-content-firat">
              <div className="bg-one">
                {" "}
                <img
                  src="assets/images/arows.png"
                  alt=""
                  className="img-fluid "
                />
              </div>
              <div className="col gotribefit-about gotribefit-about-conetnt">
                <div className="section-title">
                  <h1 className="heading-gotribefit">
                    We focus on <span>customer </span>
                    <br /> engagement!
                  </h1>
                </div>
                <div className="gotribefit-flex">
                  <p className="body-gotribefit p-bold">
                    At BigFanz, we prioritize customer engagement to create
                    meaningful connections and enhance brand loyalty. Our
                    loyalty program rewards your biggest fans for their
                    continued support, fostering a deeper relationship with your
                    brand. By personalizing your marketing, we keep your BigFanz
                    engaged and coming back for more.
                  </p>
                </div>
                <div className="industry-health-button">
                  <div className=" text-request-book">
                    <a
                      href="#requestDemo"
                      className="mt-3 mb-5 main-btn arrow-btn wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      Request a Demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-area about-area-v1 position-relative pt-130"
        id="howitwork"
      >
        <div className="container-1450">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-title text-center mb-55 wow fadeInUp">
                <h2>How it works?</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-wrapper mb-50 wow fadeInRight">
                <div className="section-title mb-15">
                  <img src="assets/images/onces.png" alt="onces" />
                  <h3>Go to store</h3>
                </div>
                <p>
                  BigFanz makes it simple to find stores that reward customer
                  loyalty. With our mobile app, available on both Android and
                  Apple phones, customers can easily locate participating stores
                  nearby. Finding places that appreciate their patronage has
                  never been easier!
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-holder-box mb-50">
                <div className="img-holder wow fadeInLeft">
                  <img src="assets/images/howitwork.png" alt="howitwork" />
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center cols-reverse">
            <div className="col-lg-6">
              <div className="img-holder-box mb-50">
                <div className="img-holder wow fadeInLeft">
                  <img src="assets/images/scan-code.png" alt="scan-code" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-wrapper mb-50 wow fadeInRight">
                <div className="section-title mb-15">
                  <img src="assets/images/twos.png" alt="twos" />
                  <h3>
                    Scan QR Code with each <br />
                    purchase & visit
                  </h3>
                </div>
                <p>
                  Upon arrival at the store, customers can check in by scanning
                  the store-specific QR code, earning points for their visit.
                  Look for the BigFanz logo and QR code—you can't miss it!
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-wrapper mb-50 wow fadeInRight">
                <div className="section-title mb-15">
                  <img src="assets/images/threes.png" alt="threes" />
                  <h3>
                    Gather points and redeem <br />
                    discounts.
                  </h3>
                </div>
                <p>
                  As customers make purchases, they earn points that can be
                  redeemed for discounts on future visits. Points are also
                  awarded for posting reviews and recommending BigFanz to
                  friends on social media. It's a rewarding cycle that keeps
                  your biggest fans coming back for more!
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-holder-box mb-50">
                <div className="img-holder wow fadeInLeft">
                  <img src="assets/images/redeem.png" alt="redeem" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="counter-area counter-area-v1 pt-100 pb-90" id="whyus">
        <div className="bg-one"></div>
        <div className="container-1450">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center mb-55 wow fadeInUp">
                <h2>Why Bigfanz</h2>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="bigfanz-iframe">
                <video
                  id="myVideo"
                  controls
                  poster="/assets/images/picture.png"
                  style={{ cursor: "pointer" }}
                >
                  <source
                    src="https://firebasestorage.googleapis.com/v0/b/reactfirebase-142f5.appspot.com/o/video.mp4?alt=media&token=1c63b0d0-73be-407d-a63c-840e9cd61d79"
                    // type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div className="row mt-75 portfolio-area-v1">
            <div className="col-lg-12">
              <div className="section-title mb-45 wow fadeInUp">
                <h2>
                  Solution <span>Overview</span>{" "}
                </h2>
                <p>
                  BigFanz offers a user-friendly set of tools that streamline
                  and simplify your customer engagement and loyalty program. The
                  end-customer app makes earning and redeeming rewards easy,
                  while businesses use a separate app to handle redemptions and
                  track the program. Our web portal simplifies store setups,
                  discount programs, and marketing. Together, these tools drive
                  loyalty and business growth with a smooth and engaging
                  experience for everyone.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="portfolio-filter-buttons">
                <div className="framed">
                  <div className="images-framed mb-4 color-1">
                    <img src="assets/images/solution1.png" alt="solution1" />
                  </div>
                  <div className="framed-listed">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Earn Rewards</strong>: Accumulate points for
                        purchases and reviews.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Redeem Discounts</strong>: Use points for
                        discounts on future visits.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Store Locator</strong>: Find participating
                        stores nearby.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Check-In</strong>: Scan QR codes to earn points
                        at stores.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Track Rewards</strong>: View and manage your
                        points balance.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="portfolio-filter-buttons">
                <div className="framed">
                  <div className="images-framed mb-4 color-2">
                    <img src="assets/images/solution2.png" alt="solution2" />
                  </div>
                  <div className="framed-listed">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Process Discounts</strong>: Easily manage and
                        track customer rewards.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Monitor Progress</strong>: View and analyze
                        loyalty program performance at the store level.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Customer Insights</strong>: Access detailed
                        customer data and engagement metrics.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Customer Feedback</strong>: View valuable
                        customer feedback and recommendations.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Real-Time Monitoring</strong>: Track and respond
                        to loyalty events as they happen.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="portfolio-filter-buttons">
                <div className="framed">
                  <div className="images-framed mb-4 color-3">
                    <img src="assets/images/solution3.png" alt="solution3" />
                  </div>
                  <div className="framed-listed">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Configure Stores</strong>: Set up and manage
                        store profiles.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Design Discount Programs</strong>: Create and
                        adjust loyalty offers.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Manage Users</strong>: Handle user permissions
                        and access.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Analyze Performance</strong>: Review reports and
                        analytics on program success.
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <strong>Marketing Campaigns</strong>: Design and deploy
                        targeted marketing initiatives.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-area" id="requestDemo">
        <div className="footer-topper">
          <div className="bg-one"></div>
          <div className="bg-two"></div>
          <div className="bg-three"></div>
          <div className="container-1450">
            <div className="footer-top pt-75 pb-40">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="section-title text-center  wow fadeInUp">
                    <h2>Request a Demo</h2>
                    <p>
                      You can request a demo filling the form below. We will
                      reach out to you with the demo video.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-widget pt-0 pb-40">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div
                    className="widget-footers-form mb-40 wow fadeInUp"
                    data-wow-delay=".20s"
                  >
                    <div className="forms-contact">
                      <form>
                        <div className="mb-5">
                          <label className="form-label">Business Name</label>
                          <input
                            className="form-control"
                            placeholder="Enter Business Name"
                            value={reqDemoData.business_name}
                            onChange={(e) =>
                              setReqDemoData({
                                ...reqDemoData,
                                business_name: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Address</label>
                          <input
                            className="form-control"
                            placeholder="Enter Address"
                            value={reqDemoData.address_line_1}
                            onChange={(e) =>
                              setReqDemoData({
                                ...reqDemoData,
                                address_line_1: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">City</label>
                          <input
                            className="form-control"
                            placeholder="Enter City"
                            value={reqDemoData.city}
                            onChange={(e) =>
                              setReqDemoData({
                                ...reqDemoData,
                                city: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">State</label>
                          <input
                            className="form-control"
                            placeholder="Enter State"
                            value={reqDemoData.state}
                            onChange={(e) =>
                              setReqDemoData({
                                ...reqDemoData,
                                state: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Zip</label>
                          <input
                            className="form-control"
                            placeholder="Enter Zip"
                            value={reqDemoData.zip}
                            onChange={(e) =>
                              setReqDemoData({
                                ...reqDemoData,
                                zip: e.target.value,
                              })
                            }
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div
                    className="widget-footers-form mb-40 wow fadeInUp"
                    data-wow-delay=".20s"
                  >
                    <div className="forms-contact">
                      <form>
                        <div className="mb-5">
                          <label className="form-label">Business Phone</label>
                          <input
                            className="form-control"
                            placeholder="Enter Business Phone"
                            value={reqDemoData.business_phone_1}
                            onChange={(e) =>
                              setReqDemoData({
                                ...reqDemoData,
                                business_phone_1: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Contact Name</label>
                          <input
                            className="form-control"
                            placeholder="Enter Contact Name"
                            value={reqDemoData.contact_name}
                            onChange={(e) =>
                              setReqDemoData({
                                ...reqDemoData,
                                contact_name: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">
                            Contact Email Address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Contact Email Address"
                            value={reqDemoData.contact_email}
                            onChange={(e) =>
                              setReqDemoData({
                                ...reqDemoData,
                                contact_email: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Contact Phone</label>
                          <input
                            className="form-control"
                            placeholder="Enter Contact Phone"
                            value={reqDemoData.contact_phone}
                            onChange={(e) =>
                              setReqDemoData({
                                ...reqDemoData,
                                contact_phone: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Comment</label>
                          <input
                            className="form-control"
                            placeholder="Enter Comment"
                            value={reqDemoData.prospect_comment}
                            onChange={(e) =>
                              setReqDemoData({
                                ...reqDemoData,
                                prospect_comment: e.target.value,
                              })
                            }
                          />
                        </div>
                        {/* <div className="mb-5">
                          <label className="form-label">Your Message</label>
                          <textarea
                            className="form-control"
                            placeholder="Enter Your Message"
                            id="exampleInputPassword4"
                            value={contactMsg}
                            onChange={(e) => setContactMsg(e.target.value)}
                          ></textarea>
                        </div> */}
                      </form>
                    </div>
                  </div>
                </div>
                <Button
                  style={{ fontFamily: "Poppins", marginLeft: 10 }}
                  loading={loading}
                  onClick={onRequestDemo}
                >
                  Request a Demo
                </Button>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="service-area pt-90 pb-80" id="price">
        <div className="container-1450">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title text-center mb-55 wow fadeInUp">
                <h2>Pricing</h2>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.{" "}
                  <br />
                  The point of using Lorem Ipsum
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {plans.map((plan, i) => (
              <div key={i} className="col-lg-4 col-md-4 col-sm-12">
                <div
                  className="service-item service-style-one mb-40 wow fadeInDown"
                  data-wow-delay=".3s"
                >
                  <div className="weekly-payments-options">
                    <div className="weekly_options">
                      {formatLY(plan.bill_frequency)}
                    </div>
                    {/* <div className="weekly_options-access">Early Access</div> */}
                  </div>
                  <div className="price-section">
                    <strong className="price">
                      <strong>${plan.plan_cost}/</strong>
                      <span>{formatBillFrequency(plan.bill_frequency)}</span>
                    </strong>
                  </div>
                  <div className="text-options">
                    <ul>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        {plan.plan_name}
                      </li>
                      <li>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        {plan.plan_desc}
                      </li>
                    </ul>
                    <a href="/registration" className="btn-links">
                      Join Now
                    </a>
                    {/* <div className="years-price">Billed Yearly</div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <footer className="footer-area" id="contact">
        <div className="footer-topper">
          <div className="bg-one"></div>
          <div className="bg-two"></div>
          <div className="bg-three"></div>
          <div className="container-1450">
            <div className="footer-top pt-75 pb-40">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="section-title text-center  wow fadeInUp">
                    <h2>Contact Us</h2>
                    <p>
                      Need help? Start a quick conversation by filling up the
                      contact us form and we will get back to you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-widget pt-0 pb-40">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div
                    className="widget-footers wow fadeInUp"
                    data-wow-delay=".15s"
                  >
                    <div className="widget-maer-forms mb-5">
                      <div className="set-align">
                        <i className="fa fa-map-marker-alt"></i>
                      </div>
                      <div className="set-align">
                        <h4 className="widget-title">Mailing Address:</h4>
                        <p>
                          <span>BigfanzInc.</span>
                          2810 N Church St, PMB 62759, Wilmington, <br />
                          Delaware 19802
                        </p>
                      </div>
                    </div>
                    <div className="widget-maer-forms ">
                      <div className="set-align">
                        <i className="fa fa-envelope"></i>
                      </div>
                      <div className="set-align">
                        <h4 className="widget-title">Further inquiries:</h4>
                        <p>
                          Please email us at{" "}
                          <a
                            style={{ color: "#fff", fontStyle: "italic" }}
                            href="mailto:support@bigfanz.com"
                          >
                            support@bigfanz.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div
                    className="widget-footers-form mb-40 wow fadeInUp"
                    data-wow-delay=".20s"
                  >
                    <div className="forms-contact">
                      <form>
                        <div className="mb-5">
                          <label className="form-label">Full Name</label>
                          <input
                            type="contact_name"
                            className="form-control"
                            placeholder="Enter Full Name"
                            aria-describedby="emailHelp"
                            value={contactName}
                            onChange={(e) => setContactName(e.target.value)}
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Email Address</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Email Address"
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Your Message</label>
                          <textarea
                            className="form-control"
                            placeholder="Enter Your Message"
                            id="exampleInputPassword4"
                            value={contactMsg}
                            onChange={(e) => setContactMsg(e.target.value)}
                          ></textarea>
                        </div>
                        <Button loading={loading} onClick={onSendMessage}>
                          Send Message
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-1450">
          <div className="footer-copyright">
            <div className="row">
              <div className="col-lg-6">
                <div className="footer-nav float-lg-left">
                  <ul>
                    <li>
                      <a href="#about">About Us </a>
                    </li>
                    <li>
                      <a href="#howitwork">How it works</a>
                    </li>
                    <li>
                      <a href="#whyus">Why Us</a>
                    </li>
                    <li>
                      <a href="#price">Pricing</a>
                    </li>
                    <li>
                      <a href="#contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="copyright-text float-lg-right">
                  <p>© 2024 - Bigfanz INC | BigFanz. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
