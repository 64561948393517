const { API_BASE_URL } = require("./../constants/ApiConstant");

export default class RegistrationService {
  static addRegistration(data) {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/v1/registration`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            resolve(res.data);
          } else {
            reject({ message: res.message });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static addAdminInRegsitration(regId, data) {
    return new Promise((resolve, reject) => {
      fetch(
        `${API_BASE_URL}/api/v1/registration/addAdminInRegistration/${regId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            resolve(res.data);
          } else {
            reject({ message: res.message });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
