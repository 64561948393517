// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stripe-payment-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .stripe-payment-label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .stripe-card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/stripePaymentForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":[".stripe-payment-form {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    margin-top: 20px;\n  }\n  \n  .stripe-payment-label {\n    font-size: 14px;\n    color: #555;\n    margin-bottom: 5px;\n  }\n  \n  .stripe-card-element {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    background: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
