const { API_BASE_URL } = require("./../constants/ApiConstant");

export default class IndustryService {
  static getIndustries(token) {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/v1/industry`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            resolve(res.data);
          } else {
            reject({ message: res.message });
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
}
