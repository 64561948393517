import { Button, Checkbox, Divider, Form, Radio } from "antd";
import React, { useEffect, useState } from "react";
import PlanService from "../../services/plans.services";

const checkboxDivStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
};

const Page4 = (props) => {
  const { allValues } = props;
  const [form] = Form.useForm();
  const theme = "light";
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [notSureFlag, setNotSureFlag] = useState(false);
  const [payPlanofInterest, setPayPlanofInterest] = useState("");
  const [autoRenewFlag, setAutoRenewFlag] = useState(false);

  useEffect(() => {
    form.setFieldsValue(allValues);
    setSelectedPlan(allValues.selectedPlan);
    setNotSureFlag(allValues.notSureFlag);
    setPayPlanofInterest(allValues.payPlanofInterest);
    setAutoRenewFlag(allValues.autoRenewFlag);
  }, [allValues, form]);

  useEffect(() => {
    PlanService.getBusinessPlans()
      .then((res) => {
        setPlans(res);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  const onPlanSelect = (myPlan) => {
    if (selectedPlan === myPlan) {
      setSelectedPlan(null);
      form.setFieldValue("selectedPlan", null);
    } else {
      setSelectedPlan(myPlan);
      form.setFieldValue("selectedPlan", myPlan);
    }
  };

  const normalStyle = { cursor: "pointer" };
  const selectedStyle = {
    cursor: "pointer",
    background: "#7030A0",
    color: "#fff",
  };

  const paymentOptions = [
    { name: "Pay monthly", value: "month", description: "$150/Month" },
    {
      name: "Pay yearly",
      value: "year",
      description: "$1800/Year - 20% Discount = $1440/yr",
    },
  ];

  const getTextStyle = (plan1, plan2) => {
    return {
      color: (plan1 === plan2 && "#fff") || "#000",
    };
  };

  const getIconStyle = (plan1, plan2) => {
    return { color: (plan1 === plan2 && "#fff") || "#7030A0" };
  };

  const formatBillFrequency = (days) => {
    if (days === 1) return "Per day";
    else if (days === 7) return "Per week";
    else if (days === 30) return "Per month";
    else if (days === 365) return "Per year";
  };

  const onSubmit = (values) => {
    props.onSubmit(
      {
        selectedPlan,
        notSureFlag,
        payPlanofInterest,
        autoRenewFlag,
        planDetail:
          plans.filter((x) => x.plan_id === selectedPlan)[0] || plans[0],
      },
      4
    );
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch(() => {
        // incomplete form
      });
  };

  return (
    <div
      style={{
        marginTop: "3%",
        width: window.innerWidth,
        padding: "0px 10% 0px 10%",
      }}
      className={`h-100 ${theme === "light" ? "bg-white" : ""}`}
    >
      <Form
        form={form}
        name="basicInformation"
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onSubmit}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select subscription",
            },
          ]}
          label="What subscription are you interested in?"
          name="selectedPlan"
        >
          <div className="row justify-content-center">
            {plans.map((myPlan, i) => (
              <div
                key={i}
                className="col-lg-4 col-md-4 col-sm-12"
                onClick={() => onPlanSelect(myPlan.plan_id)}
              >
                <div>
                  <div
                    className="service-item service-style-one mb-40 wow fadeInDown"
                    data-wow-delay=".3s"
                    style={
                      (myPlan.plan_id === selectedPlan && selectedStyle) ||
                      normalStyle
                    }
                  >
                    <div className="price-section">
                      <strong className="price">
                        <strong
                          style={getTextStyle(selectedPlan, myPlan.plan_id)}
                        >
                          ${myPlan.plan_cost}/
                        </strong>
                        <span
                          style={getTextStyle(selectedPlan, myPlan.plan_id)}
                        >
                          {formatBillFrequency(myPlan.bill_frequency)}
                        </span>
                      </strong>
                    </div>
                    <div className="text-options">
                      <ul>
                        <li>
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                            style={getIconStyle(selectedPlan, myPlan.plan_id)}
                          >
                            {/* {myPlan.plan_name} */}
                          </i>
                          {myPlan.plan_name}
                        </li>
                        <li>
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                            style={getIconStyle(selectedPlan, myPlan.plan_id)}
                          ></i>
                          {myPlan.plan_desc}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Form.Item>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: -30,
            zIndex: 111,
          }}
        >
          <Checkbox
            checked={notSureFlag}
            onChange={(e) => setNotSureFlag(e.target.checked)}
          >
            <p
              style={{
                fontSize: 16,
                fontStyle: "italic",
                color: "#000",
                marginTop: 25,
              }}
            >
              Not Sure?
            </p>
          </Checkbox>
          <a href="/#contact" target="_blank" rel="noopener noreferrer">
            <p
              style={{
                fontSize: 16,
                fontStyle: "italic",
                textDecoration: "underline",
                color: "#7030A0",
                marginTop: 25,
              }}
            >
              Contact Us
            </p>
          </a>
        </div>

        <Divider />
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select payment option",
            },
          ]}
          label="Payment Option"
          name="payPlanofInterest"
        >
          <Radio.Group
            onChange={(e) => {
              setPayPlanofInterest(e.target.value);
              form.setFieldsValue({ payPlanofInterest: e.target.value });
            }}
            value={payPlanofInterest}
          >
            {paymentOptions.map((option) => (
              <Radio value={option.value} key={option.value}>
                <div
                  style={{
                    marginTop: 25,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p
                    style={{
                      fontSize: 16,
                      color: "#000",
                      opacity: 0.6,
                    }}
                  >
                    {option.name}
                  </p>
                  <p
                    style={{
                      fontSize: 16,
                      color: "#7030A0",
                      marginLeft: 10,
                    }}
                  >
                    {option.description}
                  </p>
                </div>
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: -30,
            zIndex: 111,
          }}
        >
          <Checkbox
            checked={autoRenewFlag}
            onChange={(e) => setAutoRenewFlag(e.target.checked)}
          >
            <p
              style={{
                fontSize: 16,
                color: "#000",
                marginTop: 25,
              }}
            >
              Automatically renew my subscription
            </p>
          </Checkbox>
        </div>
        <div style={{ ...checkboxDivStyle, marginTop: 20 }}>
          <Button
            type="primary"
            // htmlType="submit"
            block
            style={{ width: 150 }}
            onClick={onFinish}
          >
            Next
          </Button>
          <Button
            type="text"
            style={{ color: "#7030A0", width: 150 }}
            onClick={props.onPrevious}
          >
            Back
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Page4;
