import { Button, Col, Divider, Form, Input, Row } from "antd";
import React from "react";
import { ROW_GUTTER } from "./../../constants/ThemeConstant";
import { PrinterFilled } from "@ant-design/icons";
import jsPDF from "jspdf";
import "jspdf-autotable";

const checkboxDivStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
};

const Page6 = (props) => {
  const theme = "light";
  const [form] = Form.useForm();
  const allValues = props.allValues;

  const generateReceipt = () => {
    const doc = new jsPDF();

    // Logo URL path relative to the public folder
    const logoUrl = `${window.location.origin}/assets/images/logo.png`;

    // Fetch the image as a Blob and then add it to the PDF
    fetch(logoUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          // Add the image to the PDF
          const pageWidth = doc.internal.pageSize.getWidth();
          const imgWidth = 50; // Logo width
          const marginLeft = pageWidth / 2 - imgWidth / 2;

          // Add the image to the PDF centered
          doc.addImage(base64data, "PNG", marginLeft, 10, 50, 15);
          // Add other elements after the image has been added
          doc.setFontSize(18);
          doc.setFont("helvetica", "bold");
          doc.text("BigFanz Receipt", 105, 50, { align: "center" });

          // Add more elements like tables, text, etc.
          doc.autoTable({
            startY: 60,
            head: [["Description", "Quantity", "Unit Price", "Total"]],
            body: [
              [
                allValues.planDetail.plan_name,
                "1",
                `$${allValues.totalAmount}`,
                `$${allValues.totalAmount}`,
              ],
            ],
            theme: "striped",
            styles: { fontSize: 11 },
            headStyles: { fillColor: [22, 160, 133] }, // Change head color
            columnStyles: {
              0: { cellWidth: 90 },
              1: { cellWidth: 20 },
              2: { cellWidth: 40 },
              3: { cellWidth: 40 },
            },
          });

          // Footer
          doc.setFontSize(12);
          doc.text(
            "Thank you for your business!",
            105,
            doc.lastAutoTable.finalY + 10,
            { align: "center" }
          );

          // Save the PDF
          doc.save("receipt.pdf");
        };
      });
  };

  const onSubmit = (values) => {
    if (values.admin_password === values.admin_confirm_password)
      props.onSubmit(values, 6);
  };

  return (
    <div
      style={{
        marginTop: "3%",
        width: window.innerWidth,
        padding: "0px 10% 0px 10%",
      }}
      className={`h-100 ${theme === "light" ? "bg-white" : ""}`}
    >
      <Form
        form={form}
        name="basicInformation"
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onSubmit}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: 24,
            color: "#7030A0",
            fontFamily: "Poppins",
          }}
        >
          Payment Processed! Thank you
        </p>
        <Button
          icon={
            <PrinterFilled
              style={{ color: "#7030A0", fontSize: 18, marginTop: -10 }}
            />
          }
          style={{
            color: "#7030A0",
            size: 18,
            borderColor: "#7030A0",
            marginBottom: 10,
            fontFamily: "Poppins",
            fontWeight: "600",
          }}
          onClick={generateReceipt}
        >
          Print Receipt
        </Button>
        <Divider />
        <Row gutter={ROW_GUTTER} justify="space-between">
          <Col xs={24} sm={24} md={24} lg={10}>
            <Form.Item
              label="First Name"
              name="admin_first_name"
              rules={[
                {
                  required: true,
                  message: "Please input admin first name",
                },
              ]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <Form.Item
              label="Last Name"
              name="admin_last_name"
              rules={[
                {
                  required: true,
                  message: "Please input admin last name",
                },
              ]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} justify="space-between">
          <Col xs={24} sm={24} md={24} lg={10}>
            <Form.Item
              label="Email Address"
              name="admin_email"
              rules={[
                {
                  required: true,
                  message: "Please input your email address",
                },
              ]}
            >
              <Input placeholder="Enter email address" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <Form.Item
              label="Phone Number"
              name="admin_phone"
              rules={[
                {
                  required: true,
                  message: "Please input admin phone number",
                },
              ]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} justify="space-between">
          <Col xs={24} sm={24} md={24} lg={10}>
            <Form.Item
              label="User Name"
              name="admin_username"
              rules={[
                {
                  required: true,
                  message: "Please input admin username",
                },
              ]}
            >
              <Input placeholder="Enter user name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <Form.Item
              label="Password"
              name="admin_password"
              rules={[
                {
                  required: true,
                  message: "Please input admin password",
                },
              ]}
            >
              <Input.Password placeholder="Enter password" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} justify="space-between">
          <Col xs={24} sm={24} md={24} lg={10}>
            <Form.Item
              label="Confirm Password"
              name="admin_confirm_password"
              rules={[
                {
                  required: true,
                  message: "Please input admin confirm password",
                },
              ]}
            >
              <Input.Password placeholder="Enter confirm password" />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ ...checkboxDivStyle, marginTop: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{ width: 150 }}
            // onClick={onS}
            loading={props.loading}
          >
            Submit
          </Button>
          {/* <Button
            type="text"
            style={{ color: "#7030A0", width: 150 }}
            onClick={props.onPrevious}
          >
            Back
          </Button> */}
          <a href="/">
            <Button type="text" style={{ color: "#7030A0" }}>
              Back to Home
            </Button>
          </a>
        </div>
      </Form>
    </div>
  );
};

export default Page6;
