const { API_BASE_URL } = require("./../constants/ApiConstant");

export default class ContactReqService {
  static addContactReq(contact_name, contact_email, contact_req_message) {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/v1/contactReq`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contact_name,
          contact_email,
          contact_req_message,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            resolve(res.data);
          } else {
            reject({ message: res.message });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
