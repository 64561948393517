import React, { useState } from "react";
import { Button, Radio } from "antd";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "./registration.css";
import StripePaymentForm from "../../components/stripePaymentForm";

const Page5 = (props) => {
  const [ccType, setccType] = useState("");
  const theme = "light";
  const stripe = useStripe();
  const elements = useElements();
  const plan = props.planDetail;
  const tax = (0.06 * plan.plan_cost).toFixed(2);
  const totalAmount = (Number(plan.plan_cost) + Number(tax)).toFixed(2);

  const onSubmit = async () => {
    const cardElement = elements.getElement(CardNumberElement);
    const { token, error } = await stripe.createToken(cardElement);
    if (token)
      props.onSubmit(
        {
          stripeToken: token.id,
          totalAmount: totalAmount,
          cc_type: ccType,
        },
        5
      );
    if (error) {
      alert("Payment failed");
    }
  };

  return (
    <div
      style={{
        marginTop: "3%",
        width: window.innerWidth,
        padding: "0px 10% 0px 10%",
      }}
      className={`h-100 ${theme === "light" ? "bg-white" : ""}`}
    >
      <div
        style={{
          backgroundColor: "#FCF9FF",
          width: "80%",
          borderRadius: 10,
          padding: 10,
          marginBottom: 20,
        }}
      >
        <p
          style={{
            color: "#7030A0",
            fontSize: 24,
            fontWeight: "600",
          }}
        >
          Payment Summary
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 22,
          }}
        >
          <p style={{ color: "#242629" }}>{plan.plan_name}</p>
          <p style={{ color: "#242629" }}>${plan.plan_cost}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 22,
          }}
        >
          <p style={{ color: "#242629" }}>Tax</p>
          <p style={{ color: "#242629" }}>+${tax}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 22,
          }}
        >
          <p style={{ color: "#242629" }}>Total Amount</p>
          <p style={{ color: "#7030A0", fontWeight: "700" }}>${totalAmount}</p>
        </div>
      </div>
      <p style={{ fontSize: 24, color: "#7030A0", fontWeight: "600" }}>
        Payment method
      </p>
      <Radio.Group
        style={{ marginBottom: 20 }}
        value={ccType}
        onChange={(e) => setccType(e.target.value)}
      >
        <Radio value="visa">
          <img
            src="/assets/images/visalogo.png"
            style={{ width: 80, height: 70, marginRight: 10 }}
            alt="visalogo"
          />
        </Radio>
        <Radio value="mastercard">
          <img
            src="/assets/images/mastercardlogo.png"
            style={{ width: 60, height: 50, marginRight: 10 }}
            alt="mastercardlogo"
          />
        </Radio>
        <Radio value="americanexpress">
          <img
            src="/assets/images/amexlogo.png"
            style={{ width: 60, height: 50, marginRight: 10 }}
            alt="amexlogo"
          />
        </Radio>
        <Radio value="paypal">
          <img
            src="/assets/images/paypallogo.png"
            style={{ width: 60, height: 50, marginRight: 10 }}
            alt="paypallogo"
          />
        </Radio>
      </Radio.Group>
      {/* <CardElement /> */}
      <StripePaymentForm />
      {/* flex-row-start */}
      <div className="flex-row-start" style={{ marginTop: 40 }}>
        <Button
          type="primary"
          // htmlType="submit"
          block
          style={{ width: 150 }}
          onClick={onSubmit}
          loading={props.loading}
        >
          Next
        </Button>
        <Button
          type="text"
          style={{ color: "#7030A0", width: 150 }}
          onClick={props.onPrevious}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default Page5;
