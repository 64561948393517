import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  //   PostalCodeElement,
} from "@stripe/react-stripe-js";
import "./stripePaymentForm.css"; // Make sure to create and import the CSS file for additional styling
import { Col } from "antd";

const StripePaymentForm = () => {
  const ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <div className="stripe-payment-form">
      <Col xs={24} sm={24} md={10}>
        <label className="stripe-payment-label">Card Number</label>
        <CardNumberElement
          options={ELEMENT_OPTIONS}
          className="stripe-card-element"
        />
      </Col>
      <Col xs={24} sm={24} md={10}>
        <label className="stripe-payment-label">Expiration Date</label>
        <CardExpiryElement
          options={ELEMENT_OPTIONS}
          className="stripe-card-element"
        />
      </Col>
      <Col xs={24} sm={24} md={10}>
        <label className="stripe-payment-label">CVC</label>
        <CardCvcElement
          options={ELEMENT_OPTIONS}
          className="stripe-card-element"
        />
      </Col>
      {/* <label className="stripe-payment-label">Postal Code</label> */}
      {/* <PostalCodeElement
        options={ELEMENT_OPTIONS}
        className="stripe-card-element"
      /> */}
    </div>
  );
};

export default StripePaymentForm;
