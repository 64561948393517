// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input.ant-input{
  border-color: rgb(127, 127, 127);
}

.ant-select-selector {
  border-color: rgb(127, 127, 127) !important;
}

.ant-checkbox-inner {
  border-color: rgb(127, 127, 127) !important;
  border-width: 1px !important;
}

.rounded-icon-div {
  background-color: rgb(104, 52, 154);
  width: 20px;
  height: 20px;
  border-radius: 20px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  cursor: pointer;
}

.rounded-icon-div-icon {
  border-radius: 25px;
  font-size: 15px;
  border-width: 10px;
  border-color: rgb(104, 52, 154);
  color: #fff;
  border-style: solid;
  background-color: rgb(104, 52, 154);
  transition: all 0.3s ease; 
} 

.rounded-icon-div-icon:hover {
  border-width: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;iBAEe;EACf,mCAAmC;EACnC,kCAAkC;EAClC,sBAAsB;AACxB;;AAEA;EACE;aACW;AACb;;;AAGA;EACE,gCAAgC;AAClC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,2CAA2C;EAC3C,4BAA4B;AAC9B;;AAEA;EACE,mCAAmC;EACnC,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,+BAA+B;EAC/B,WAAW;EACX,mBAAmB;EACnB,mCAAmC;EACnC,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["body {\n  margin: 0;\n  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-family: 'Poppins';\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\ninput.ant-input{\n  border-color: rgb(127, 127, 127);\n}\n\n.ant-select-selector {\n  border-color: rgb(127, 127, 127) !important;\n}\n\n.ant-checkbox-inner {\n  border-color: rgb(127, 127, 127) !important;\n  border-width: 1px !important;\n}\n\n.rounded-icon-div {\n  background-color: rgb(104, 52, 154);\n  width: 20px;\n  height: 20px;\n  border-radius: 20px;\n  padding: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  justify-self: center;\n  cursor: pointer;\n}\n\n.rounded-icon-div-icon {\n  border-radius: 25px;\n  font-size: 15px;\n  border-width: 10px;\n  border-color: rgb(104, 52, 154);\n  color: #fff;\n  border-style: solid;\n  background-color: rgb(104, 52, 154);\n  transition: all 0.3s ease; \n} \n\n.rounded-icon-div-icon:hover {\n  border-width: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
