import React from "react";
import { MenuOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import "./style.css";

const Navigation = () => {
  return (
    <header className="header">
      <a href="/">
        <img className="logo" src="assets/images/logo.png" alt="logo" />
      </a>

      <input type="checkbox" id="check" />
      <label htmlFor="check" className="icons">
        <MenuOutlined className="menu-icon" />
        <CloseOutlined className="closed-icon" />
      </label>
      <nav className="navbar">
        <a href="#about" className="navbar-link">
          About Us
        </a>
        <a href="#howitwork" className="navbar-link">
          How it works
        </a>
        <a href="#whyus" className="navbar-link">
          Why Us
        </a>
        <a href="#price" className="navbar-link">
          Pricing
        </a>
        <a href="#contact" className="navbar-link">
          Contact Us
        </a>
        <a
          href="https://business.bigfanz.com/"
          target="_blank"
          rel="noreferrer"
          className="btns-login"
        >
          <span>Login</span>
        </a>
      </nav>
    </header>
  );
};

export default Navigation;
